export default {
  heading: {
    id: 'product_macbook_processor_modal_heading',
    defaultMessage: 'Understanding processors: Intel vs. Apple Silicon',
  },
  intro: {
    id: 'product_macbook_processor_modal_intro',
    defaultMessage:
      'The brain of your MacBook (aka its "processor") will be Intel or Apple Silicon depending on its release year.',
  },
  sectionIntelTitle: {
    id: 'product_macbook_processor_modal_section_intel_title',
    defaultMessage: 'Intel (pre-2021)',
  },
  sectionIntelDescription: {
    id: 'product_macbook_processor_modal_section_intel_description',
    defaultMessage:
      'If your MacBook was released before 2021, it’ll have an Intel processor.',
  },
  sectionIntelCoreI3Title: {
    id: 'product_macbook_processor_modal_core_i3_title',
    defaultMessage: 'Core i3',
  },
  sectionIntelCoreI3Item1: {
    id: 'product_macbook_processor_modal_core_i3_item_1',
    defaultMessage: 'Basic performance',
  },
  sectionIntelCoreI3Item2: {
    id: 'product_macbook_processor_modal_core_i3_item_2',
    defaultMessage: 'Web browsing & word processing',
  },
  sectionIntelCoreI3Item3: {
    id: 'product_macbook_processor_modal_core_i3_item_3',
    defaultMessage: 'Videos & music',
  },
  sectionIntelCoreI5Title: {
    id: 'product_macbook_processor_modal_core_i5_title',
    defaultMessage: 'Core i5',
  },
  sectionIntelCoreI5Item1: {
    id: 'product_macbook_processor_modal_core_i5_item_1',
    defaultMessage: 'Normal performance',
  },
  sectionIntelCoreI5Item2: {
    id: 'product_macbook_processor_modal_core_i5_item_2',
    defaultMessage: 'Multi-tasking for everyday use',
  },
  sectionIntelCoreI5Item3: {
    id: 'product_macbook_processor_modal_core_i5_item_3',
    defaultMessage: 'Photo & light video editing',
  },
  sectionIntelCoreI7Title: {
    id: 'product_macbook_processor_modal_core_i7_title',
    defaultMessage: 'Core i7',
  },
  sectionIntelCoreI7Item1: {
    id: 'product_macbook_processor_modal_core_i7_item_1',
    defaultMessage: 'Highest performance',
  },
  sectionIntelCoreI7Item2: {
    id: 'product_macbook_processor_modal_core_i7_item_2',
    defaultMessage: 'Powered for creatives',
  },
  sectionIntelCoreI7Item3: {
    id: 'product_macbook_processor_modal_core_i7_item_3',
    defaultMessage: 'Video editing & gaming',
  },
  sectionAppleSiliconTitle: {
    id: 'product_macbook_processor_modal_section_apple_silicon_title',
    defaultMessage: 'Apple Silicon (post-2020)',
  },
  sectionAppleSiliconDescription: {
    id: 'product_macbook_processor_modal_section_apple_silicon_description',
    defaultMessage:
      'If your MacBook was released after late 2020, it’ll have an M-series Apple Sillicon processor.',
  },
  sectionAppleSiliconBaseTitle: {
    id: 'product_macbook_processor_modal_apple_silicon_base_title',
    defaultMessage: 'M-series Base',
  },
  sectionAppleSiliconBaseItem1: {
    id: 'product_macbook_processor_modal_apple_silicon_base_item_1',
    defaultMessage: 'Powered for creatives',
  },
  sectionAppleSiliconBaseItem2: {
    id: 'product_macbook_processor_modal_apple_silicon_base_item_2',
    defaultMessage: 'Photo & light video editing',
  },
  sectionAppleSiliconBaseItem3: {
    id: 'product_macbook_processor_modal_apple_silicon_base_item_3',
    defaultMessage: 'Makes multi-tasking easy',
  },
  sectionAppleSiliconProTitle: {
    id: 'product_macbook_processor_modal_apple_silicon_pro_title',
    defaultMessage: 'M-series Pro',
  },
  sectionAppleSiliconProItem1: {
    id: 'product_macbook_processor_modal_apple_silicon_pro_item_1',
    defaultMessage: 'Highest performance',
  },
  sectionAppleSiliconProItem2: {
    id: 'product_macbook_processor_modal_apple_silicon_pro_item_2',
    defaultMessage: 'Video & 3D rendering',
  },
  sectionAppleSiliconProItem3: {
    id: 'product_macbook_processor_modal_apple_silicon_pro_item_3',
    defaultMessage: 'Handles heavy workloads',
  },
  sectionAppleSiliconMaxTitle: {
    id: 'product_macbook_processor_modal_apple_silicon_max_title',
    defaultMessage: 'M-series Max',
  },
  sectionAppleSiliconMaxItem1: {
    id: 'product_macbook_processor_modal_apple_silicon_max_item_1',
    defaultMessage: 'Highest performance',
  },
  sectionAppleSiliconMaxItem2: {
    id: 'product_macbook_processor_modal_apple_silicon_max_item_2',
    defaultMessage: 'Video & 3D rendering',
  },
  sectionAppleSiliconMaxItem3: {
    id: 'product_macbook_processor_modal_apple_silicon_max_item_3',
    defaultMessage: 'Handles heavy workloads',
  },
  sectionAppleSiliconInfoBlock: {
    id: 'product_macbook_processor_modal_apple_silicon_info_block',
    defaultMessage:
      'Higher M-series have more CPU and GPU cores (the brainpower of your MacBook), enhancing performance.',
  },
  videoTitle: {
    id: 'product_macbook_processor_modal_video_title',
    defaultMessage: 'Watch to learn more',
  },
  videoDescription: {
    id: 'product_macbook_processor_modal_video_description',
    defaultMessage:
      'Understanding processors: Intel vs. Apple Silicon on YouTube',
  },
}

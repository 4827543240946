<template>
  <div class="px-24">
    <IconCpu class="mx-auto mt-24" size="48" />

    <section class="mt-32 text-center">
      <h1 class="heading-1 mb-4">{{ i18n(translations.heading) }}</h1>
      <p class="body-1">{{ i18n(translations.intro) }}</p>
    </section>

    <RevDivider class="my-32" />

    <h2 class="text-static-brand-low heading-2 mb-4">
      {{ i18n(translations.sectionIntelTitle) }}
    </h2>
    <p class="body-1">{{ i18n(translations.sectionIntelDescription) }}</p>
  </div>

  <section class="overflow-hidden">
    <div class="overflow-auto px-8">
      <table
        class="mood-blush mt-16 w-full table-fixed border-separate border-spacing-x-[16px] text-center"
      >
        <thead>
          <tr class="">
            <th class="w-[148px] pb-20 pt-12">
              {{ i18n(translations.sectionIntelCoreI3Title) }}
            </th>
            <th class="w-[148px] pb-20 pt-12">
              {{ i18n(translations.sectionIntelCoreI5Title) }}
            </th>
            <th class="w-[148px] pb-20 pt-12">
              {{ i18n(translations.sectionIntelCoreI7Title) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="bg-static-default-hi rounded-t-sm p-16">
              {{ i18n(translations.sectionIntelCoreI3Item1) }}
            </td>
            <td class="bg-static-default-hi rounded-t-sm p-16">
              {{ i18n(translations.sectionIntelCoreI5Item1) }}
            </td>
            <td class="bg-static-default-hi rounded-t-sm p-16">
              {{ i18n(translations.sectionIntelCoreI7Item1) }}
            </td>
          </tr>
          <tr>
            <td class="bg-static-default-hi p-16">
              {{ i18n(translations.sectionIntelCoreI3Item2) }}
            </td>
            <td class="bg-static-default-hi p-16">
              {{ i18n(translations.sectionIntelCoreI5Item2) }}
            </td>
            <td class="bg-static-default-hi p-16">
              {{ i18n(translations.sectionIntelCoreI7Item2) }}
            </td>
          </tr>
          <tr>
            <td class="bg-static-default-hi rounded-b-sm p-16">
              {{ i18n(translations.sectionIntelCoreI3Item3) }}
            </td>
            <td class="bg-static-default-hi rounded-b-sm p-16">
              {{ i18n(translations.sectionIntelCoreI5Item3) }}
            </td>
            <td class="bg-static-default-hi rounded-b-sm p-16">
              {{ i18n(translations.sectionIntelCoreI7Item3) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <div class="px-24">
    <RevDivider class="my-48" />
    <h2 class="text-static-brand-mid heading-2 mb-4">
      {{ i18n(translations.sectionAppleSiliconTitle) }}
    </h2>
    <p class="body-1">
      {{ i18n(translations.sectionAppleSiliconDescription) }}
    </p>
  </div>

  <section class="overflow-hidden">
    <div class="overflow-auto px-8">
      <table
        class="mood-purple mt-16 w-full table-fixed border-separate border-spacing-x-[16px] text-center"
      >
        <thead>
          <tr class="">
            <th class="w-[148px] pb-20 pt-12">
              {{ i18n(translations.sectionAppleSiliconBaseTitle) }}
            </th>
            <th class="w-[148px] pb-20 pt-12">
              {{ i18n(translations.sectionAppleSiliconProTitle) }}
            </th>
            <th class="w-[148px] pb-20 pt-12">
              {{ i18n(translations.sectionAppleSiliconMaxTitle) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="bg-static-default-hi rounded-t-sm p-16">
              {{ i18n(translations.sectionAppleSiliconBaseItem1) }}
            </td>
            <td class="bg-static-default-hi rounded-t-sm p-16">
              {{ i18n(translations.sectionAppleSiliconProItem1) }}
            </td>
            <td class="bg-static-default-hi rounded-t-sm p-16">
              {{ i18n(translations.sectionAppleSiliconMaxItem1) }}
            </td>
          </tr>
          <tr>
            <td class="bg-static-default-hi p-16">
              {{ i18n(translations.sectionAppleSiliconBaseItem2) }}
            </td>
            <td class="bg-static-default-hi p-16">
              {{ i18n(translations.sectionAppleSiliconProItem2) }}
            </td>
            <td class="bg-static-default-hi p-16">
              {{ i18n(translations.sectionAppleSiliconMaxItem2) }}
            </td>
          </tr>
          <tr>
            <td class="bg-static-default-hi rounded-b-sm p-16">
              {{ i18n(translations.sectionAppleSiliconBaseItem3) }}
            </td>
            <td class="bg-static-default-hi rounded-b-sm p-16">
              {{ i18n(translations.sectionAppleSiliconProItem3) }}
            </td>
            <td class="bg-static-default-hi rounded-b-sm p-16">
              {{ i18n(translations.sectionAppleSiliconMaxItem3) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <div class="px-24 pb-24">
    <RevInfoBlock
      class="mt-16"
      :icon="IconLightbulb"
      :title="i18n(translations.sectionAppleSiliconInfoBlock)"
      variant="default"
    />

    <div v-if="showYoutubeVideo" class="mt-48">
      <h2 class="heading-3 mb-8">{{ i18n(translations.videoTitle) }}</h2>
      <YoutubeEmbedded
        :placeholder-attrs="{ class: tw`!h-auto aspect-video` }"
        :title="i18n(translations.videoDescription)"
        :youtube-id="YOUTUBE_VIDEO_IDS[countryCode]!"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import YoutubeEmbedded from '@backmarket/nuxt-layer-cms/YoutubeEmbedded.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevDivider } from '@ds/components/Divider'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconCpu } from '@ds/icons/IconCpu'
import { IconLightbulb } from '@ds/icons/IconLightbulb'
import { tw } from '@ds/utils/tailwind'

import { YOUTUBE_VIDEO_IDS } from './ProcessorModalBody.constants'
import translations from './ProcessorModalBody.translations'

const i18n = useI18n()
const {
  market: { countryCode },
} = useMarketplace()

const showYoutubeVideo = computed(() => {
  return !!YOUTUBE_VIDEO_IDS[countryCode]
})
</script>
